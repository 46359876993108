export const BSC = 56
export const ARBITRUM = 42161
export const AVALANCHE = 43114
export const FANTOM = 250
export const FANTOM_OLD = 0

export const addresses = {
    [BSC]: {
        GMX: '0x94C6B279b5df54b335aE51866d6E2A56BF5Ef9b7',
        BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        BNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        RewardReader: '0xfC9A1308ccd5A6aF345efA9Fa5e11643487Bf5e0',
        GLP: '0xbd1dCEc2103675C8F3953c34aE40Ed907E1DCAC2',
        GlpManager: '0x749DA3a34A6E1b098F3BFaEd23DAD2b7D7846b9B'
    },

    [ARBITRUM]: {
        GMX: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
        BTC: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
        ETH: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        LINK: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        UNI: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
        DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        RewardReader: '0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0',
        GLP: '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258',
        GlpManager: '0x321F653eED006AD1C29D174e17d96351BDe22649'
    },

    [FANTOM_OLD]: {
        GMX: '0x66eEd5FF1701E6ed8470DC391F05e27B1d0657eb',
        BTC: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
        ETH: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
        FTM: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
        DAI: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
        RewardReader: '0x512F8D4E28EB53A6d036aEDA9C5a4D1De6DBD543',
        GLP: '0xd5c313DE2d33bf36014e6c659F13acE112B80a8E',
        GlpManager: '0xA3Ea99f8aE06bA0d9A6Cf7618d06AEa4564340E9'
    },

    [FANTOM]: {
        GMX: '0x66eEd5FF1701E6ed8470DC391F05e27B1d0657eb',
        FTM: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
        LZBTC: '0xf1648C50d2863f780c57849D812b4B7686031A3D',
        AXLBTC: '0x448d59B4302aB5d2dadf9611bED9457491926c8e',
        LZETH: '0x695921034f0387eAc4e11620EE91b1b15A6A09fE',
        AXLETH: '0xfe7eDa5F2c56160d406869A8aA4B2F365d544C7B',
        RewardReader: '0x512F8D4E28EB53A6d036aEDA9C5a4D1De6DBD543',
        GLP: '0xF476F7F88E70470c976d9DF7c5C003dB1E1980Cb',
        GlpManager: '0x3A15Bac2D87C89F08147353fc9aE27080631b73d'
    },

    [AVALANCHE]: {
        GMX: '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a',
        AVAX: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        ETH: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
        BTC: '0x50b7545627a5162f82a992c33b87adc75187b218',
        RewardReader: '0x04Fc11Bd28763872d143637a7c768bD96E44c1b6',
        GLP: '0x01234181085565ed162a948b6a5e88758CD7c7b8',
        GlpManager: '0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F'
    }
}

export function getAddress(chainId, key) {
    if (!(chainId) in addresses) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
