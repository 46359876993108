import { useMemo, useState, useEffect } from 'react'
import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client'
import { chain, sumBy, sortBy, maxBy, minBy } from 'lodash'
import fetch from 'cross-fetch';
import * as ethers from 'ethers'

import { fillPeriods } from './helpers'
import { addresses, getAddress, FANTOM, BSC, FANTOM_OLD } from './addresses'

const BigNumber = ethers.BigNumber
const formatUnits = ethers.utils.formatUnits
const { JsonRpcProvider } = ethers.providers

import RewardReader from '../abis/RewardReader.json'
import GlpManager from '../abis/GlpManager.json'
import Token from '../abis/v1/Token.json'

const providers = {
  fantomOld: new JsonRpcProvider('https://rpc.ankr.com/fantom'),
  fantom: new JsonRpcProvider('https://rpc.ankr.com/fantom'),
  bsc: new JsonRpcProvider('https://rpc.ankr.com/bsc')
}

function getProvider(chainName) {
  if (!(chainName in providers)) {
    throw new Error(`Unknown chain ${chainName}`)
  }
  return providers[chainName]
}

function getChainId(chainName) {
  const chainId = {
    fantomOld: FANTOM_OLD,
    fantom: FANTOM,
    bsc: BSC
  }[chainName]
  if (!chainId) {
    throw new Error(`Unknown chain ${chainName}`)
  }
  return chainId
}

const NOW_TS = parseInt(Date.now() / 1000)
const FIRST_DATE_TS = parseInt(+(new Date(2023, 3, 5)) / 1000)

function fillNa(arr) {
  const prevValues = {}
  let keys
  if (arr.length > 0) {
    keys = Object.keys(arr[0])
    delete keys.timestamp
    delete keys.id
  }

  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key]
        }
      } else {
        prevValues[key] = el[key]
      }
    }
  }
  return arr
}

export async function queryEarnData(chainName, account) {
  const provider = getProvider(chainName)
  const chainId = getChainId(chainName)
  const rewardReader = new ethers.Contract(getAddress(chainId, 'RewardReader'), RewardReader.abi, provider)
  const glpContract = new ethers.Contract(getAddress(chainId, 'GLP'), Token.abi, provider)
  const glpManager = new ethers.Contract(getAddress(chainId, 'GlpManager'), GlpManager.abi, provider)

  let depositTokens
  let rewardTrackersForDepositBalances
  let rewardTrackersForStakingInfo

  if (chainId === FANTOM) {
    depositTokens = [
      '0x66eEd5FF1701E6ed8470DC391F05e27B1d0657eb', // MPX
      '0xe0f606e6730bE531EeAf42348dE43C2feeD43505', // esMPX
      '0xa4157E273D88ff16B3d8Df68894e1fd809DbC007', // sMPX
      '0xa2242d0A8b0b5c1A487AbFC03Cd9FEf6262BAdCA', // sbMPX
      '0xEf187825c6CdA0570B717a8E6fDa734812EC0b09', // bnMPX
      '0xd5c313DE2d33bf36014e6c659F13acE112B80a8E' // MLP
    ]
    rewardTrackersForDepositBalances = [
      '0xa4157E273D88ff16B3d8Df68894e1fd809DbC007', // sMPX
      '0xa4157E273D88ff16B3d8Df68894e1fd809DbC007', // sMPX
      '0xa2242d0A8b0b5c1A487AbFC03Cd9FEf6262BAdCA', // sbMPX
      '0x2D5875ab0eFB999c1f49C798acb9eFbd1cfBF63c', // sbfMPX
      '0x2D5875ab0eFB999c1f49C798acb9eFbd1cfBF63c', // sbfMPX
      '0xd3C5dEd5F1207c80473D39230E5b0eD11B39F905' // fMLP
    ]
    rewardTrackersForStakingInfo = [
      '0xa4157E273D88ff16B3d8Df68894e1fd809DbC007', // sMPX
      '0xa2242d0A8b0b5c1A487AbFC03Cd9FEf6262BAdCA', // sbMPX
      '0x2D5875ab0eFB999c1f49C798acb9eFbd1cfBF63c', // sbfMPX
      '0x49A97680938B4F1f73816d1B70C3Ab801FAd124B', // fsMLP
      '0xd3C5dEd5F1207c80473D39230E5b0eD11B39F905' // fMLP
    ]
  } else if (chainId === FANTOM_OLD) {
    depositTokens = [
      '0x66eEd5FF1701E6ed8470DC391F05e27B1d0657eb', // MPX
      '0xe0f606e6730bE531EeAf42348dE43C2feeD43505', // esMPX
      '0xa4157E273D88ff16B3d8Df68894e1fd809DbC007', // sMPX
      '0xa2242d0A8b0b5c1A487AbFC03Cd9FEf6262BAdCA', // sbMPX
      '0xEf187825c6CdA0570B717a8E6fDa734812EC0b09', // bnMPX
      '0xF476F7F88E70470c976d9DF7c5C003dB1E1980Cb' // MLP
    ]
    rewardTrackersForDepositBalances = [
      '0xa4157E273D88ff16B3d8Df68894e1fd809DbC007', // sMPX
      '0xa4157E273D88ff16B3d8Df68894e1fd809DbC007', // sMPX
      '0xa2242d0A8b0b5c1A487AbFC03Cd9FEf6262BAdCA', // sbMPX
      '0x2D5875ab0eFB999c1f49C798acb9eFbd1cfBF63c', // sbfMPX
      '0x2D5875ab0eFB999c1f49C798acb9eFbd1cfBF63c', // sbfMPX
      '0x0Af7E9F3396423C30a4dF4a79882d118ea89e2F2' // fMLP
    ]
    rewardTrackersForStakingInfo = [
      '0xa4157E273D88ff16B3d8Df68894e1fd809DbC007', // sMPX
      '0xa2242d0A8b0b5c1A487AbFC03Cd9FEf6262BAdCA', // sbMPX
      '0x2D5875ab0eFB999c1f49C798acb9eFbd1cfBF63c', // sbfMPX
      '0xB30A97548551Ac8b185685FC25bF3564cE6E716D', // fsMLP
      '0x0Af7E9F3396423C30a4dF4a79882d118ea89e2F2' // fMLP
    ]
  } else if (chainId === BSC) {
      depositTokens = [
        '0x94C6B279b5df54b335aE51866d6E2A56BF5Ef9b7', // MPX
        '0x620E501F70cc0989f7C6A700C457B0fa0207b51B', // esMPX
        '0x13d2bBAE955c54Ab99F71Ff70833dE64482519B1', // sMPX
        '0xdBa3A9993833595eAbd2cDE1c235904ad0fD0b86', // sbMPX
        '0xd865b08065d9D4dF692d01CCd5EA06408d2E5A20', // bnMPX
        '0xbd1dCEc2103675C8F3953c34aE40Ed907E1DCAC2' // MLP
      ]
      rewardTrackersForDepositBalances = [
        '0x13d2bBAE955c54Ab99F71Ff70833dE64482519B1', // sMPX
        '0x13d2bBAE955c54Ab99F71Ff70833dE64482519B1', // sMPX
        '0xdBa3A9993833595eAbd2cDE1c235904ad0fD0b86', // sbMPX
        '0xfAEdbA0E97D5DCD7A29fB6778D7e17b1be35c0b8', // sbfMPX
        '0xfAEdbA0E97D5DCD7A29fB6778D7e17b1be35c0b8', // sbfMPX
        '0x1Fc9aB3b7bEE66fC29167AB205777537898ff235' // fMLP
      ]
      rewardTrackersForStakingInfo = [
        '0x13d2bBAE955c54Ab99F71Ff70833dE64482519B1', // sMPX
        '0xdBa3A9993833595eAbd2cDE1c235904ad0fD0b86', // sbMPX
        '0xfAEdbA0E97D5DCD7A29fB6778D7e17b1be35c0b8', // sbfMPX
        '0x4e0e48b787E308049d0CA6bfAA84D5c61c5a4A1e', // fsMLP
        '0x1Fc9aB3b7bEE66fC29167AB205777537898ff235' // fMLP
      ]
  }

  const [
    balances,
    stakingInfo,
    glpTotalSupply,
    glpAum,
    gmxPrice
  ] = await Promise.all([
    rewardReader.getDepositBalances(account, depositTokens, rewardTrackersForDepositBalances),
    rewardReader.getStakingInfo(account, rewardTrackersForStakingInfo).then(info => {
      return rewardTrackersForStakingInfo.map((_, i) => {
        return info.slice(i * 5, (i + 1) * 5)
      })
    }),
    glpContract.totalSupply(),
    glpManager.getAumInUsdg(true),
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=mpx&vs_currencies=usd').then(async res => {
      const j = await res.json()
      return j['mpx']['usd']
    })
  ])

  const glpPrice = (glpAum / 1e18) / (glpTotalSupply / 1e18)
  const now = new Date()

  return {
    GLP: {
      stakedGLP: balances[5] / 1e18,
      pendingETH: stakingInfo[4][0] / 1e18,
      pendingEsGMX: stakingInfo[3][0] / 1e18,
      glpPrice
    },
    GMX: {
      stakedGMX: balances[0] / 1e18,
      stakedEsGMX: balances[1] / 1e18,
      pendingETH: stakingInfo[2][0] / 1e18,
      pendingEsGMX: stakingInfo[0][0] / 1e18,
      gmxPrice
    },
    timestamp: parseInt(now / 1000),
    datetime: now.toISOString()
  }
}

export const tokenSymbols = {
  // Fantom Old
  '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83': 'FTM',
  '0x321162cd933e2be498cd2267a90534a804051b11': 'BTC',
  '0x74b23882a30290451a17c44f4f05243b6b58c76d': 'ETH',
  '0x04068da6c83afcfa0e13ba15a6696662335d5b75': 'USDC',
  '0x049d68029688eabf473097a2fc38ef61633a3c7a': 'USDT',
  '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e': 'DAI',

  // Fantom
  '0x695921034f0387eac4e11620ee91b1b15a6a09fe': 'lzETH',
  '0xfe7eda5f2c56160d406869a8aa4b2f365d544c7b': 'axlETH',
  '0xf1648c50d2863f780c57849d812b4b7686031a3d': 'lzBTC',
  '0x448d59b4302ab5d2dadf9611bed9457491926c8e': 'axlBTC',
  '0x28a92dde19d9989f39a49905d7c9c2fac7799bdf': 'lzUSDC',
  '0x1b6382dbdea11d97f24495c9a90b7c88469134a4': 'axlUSDC',
  '0xcc1b99ddac1a33c201a742a1851662e87bc7f22c': 'lzUSDT',
  '0xd226392c23fb3476274ed6759d4a478db3197d82': 'axlUSDT',
  '0x2f733095b80a04b38b0d10cc884524a3d09b836a': 'USDC.e',

  // BSC
  '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': 'BNB',
  '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c': 'BTC',
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8': 'ETH',
  '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe': 'XRP',
  '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47': 'ADA',
  '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d': 'USDC',
  '0x55d398326f99059ff775485246999027b3197955': 'USDT',
  '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82': 'CAKE',
}

const knownSwapSources = {
  fantom: {
    '0x46940Dc651bFe3F2CC3E04cf9dC5579B50Cf0765': 'MPX OrderBook',
    '0x3Acf67bD8C291F9C5bbBB14AC0eC86F60ABCE36E': 'MPX Router',
    '0x89BE4cF89c425F74b2d0691A268A9a421e9dce7b': 'MPX FastPriceFeed', // FastPriceFeed
    '0x366152Fc0FC4680e0A05ce9739a4210228C72BA3': 'MPX PositionManager', // PositionManager
    '0x26e6C47682FfC1824d7aC5512752FC671dA5e607': 'MPX PositionRouter',
    '0xe0C38b2a8D09aAD53f1C67734B9A95E43d5981c0': 'Firebird',
    '0xA058b1A0bA31590d1E14A1F157f4ff7D41c78077': 'MPX PositionExecutor' // Position Executor
  },
  fantomOld: {
    '0x3CF6fE161Ee517B5002feFa31Ed478681FAed8C9': 'MPX OrderBook',
    '0x3D5343749279a974c16FCFF3515879C0e18E91C4': 'MPX Router',
    '0x7f54C35A38D89fcf5Fe516206E6628745ed38CC7': 'MPX FastPriceFeed', // FastPriceFeed
    '0x2F66E711294328587e16E8912ae08bAD979feaAb': 'MPX PositionManager', // PositionManager
    '0x5D90059b8116906bF8c1c7B7E3920A4b6e9DF4dB': 'MPX PositionRouter',
    '0xe0C38b2a8D09aAD53f1C67734B9A95E43d5981c0': 'Firebird',
    '0xA058b1A0bA31590d1E14A1F157f4ff7D41c78077': 'MPX PositionExecutor' // Position Executor
  },
  bsc: {
    '0x73bF80506F891030570FDC4D53a71f44a442353C': 'MPX OrderBook',
    '0x26e6C47682FfC1824d7aC5512752FC671dA5e607': 'MPX Router',
    '0x55e6e6A968e485abEC1e1d957f408586e45a4f99': 'MPX FastPriceFeed', // FastPriceFeed
    '0x06c35893Ba9bc454e12c36F4117BC99f75e34346': 'MPX PositionManager', // PositionManager
    '0x05D97A8a5eF11010a6A5f89B3D4628ce43092614': 'MPX PositionRouter',
    '0xA058b1A0bA31590d1E14A1F157f4ff7D41c78077': 'MPX PositionExecutor' // Position Executor
  }
}

const defaultFetcher = url => fetch(url).then(res => res.json())
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultValue)

  useEffect(async () => {
    try {
      setLoading(true)
      const data = await fetcher(url)
      setData(data)
    } catch (ex) {
      console.error(ex)
      setError(ex)
    }
    setLoading(false)
  }, [url])

  return [data, loading, error]
}

export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    lzBTC: 'bitcoin',
    axlBTC: 'bitcoin',
    lzETH: 'ethereum',
    axlETH: 'ethereum',
    BTC: 'bitcoin',
    ETH: 'ethereum',
    XRP: 'ripple',
    ADA: 'cardano',
    BNB: 'binancecoin',
    FTM: 'fantom',
  }[symbol]

  const now = Date.now() / 1000
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`

  const [res, loading, error] = useRequest(url)

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null
    }

    const ret = res.prices.map(item => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for MLP we store last price of the day
      const timestamp = item[0] - 1
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400
      return {
        timestamp: groupTs,
        value: item[1]
      }
    })
    return ret
  }, [res])

  return [data, loading, error]
}

function getImpermanentLoss(change) {
  return 2 * Math.sqrt(change) / (1 + change) - 1
}

function getChainSubgraph(chainName) {
  if (chainName === 'fantom') {
    return 'https://api-v2.morphex.trade/subgraph/6GjHurahqYLUUYkqfCgrWfcH2pfTEFPtPvCPvQ1BHLed'
  } else if (chainName === 'fantomOld') {
    return 'https://api-v2.morphex.trade/subgraph/EH6ZfhnYQd7Kv1SdnUAp96vMUWKCTfPrctwududH5cmG'
  } else if (chainName === 'bsc') {
    return 'https://api-v2.morphex.trade/subgraph/4Zdyx9D4oYLGSm1C26jpTU7Ho7ecswEuTPg3WANGkMTx'
  }
  // return chainName === 'fantom' ? 'morphex-labs/morphex-fantom-stats' : 'morphex-labs/morphex-bsc-stats';
}

export function useGraph(querySource, { subgraph = null, chainName = "fantom" } = {}) {
  const query = gql(querySource)

  if (!subgraph) {
    subgraph = getChainSubgraph(chainName)
  }

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraph, fetch }),
    cache: new InMemoryCache()
  })
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
  }, [querySource, setLoading])

  useEffect(() => {
    client.query({query}).then(res => {
      setData(res.data)
      setLoading(false)
    }).catch(ex => {
      console.warn('Subgraph request failed error: %s subgraphUrl: %s', ex.message, subgraph)
      setError(ex)
      setLoading(false)
    })
  }, [querySource, setData, setError, setLoading])

  return [data, loading, error]
}

export function useLastBlock(chainName = "fantom") {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  useEffect(() => {
    providers[chainName].getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  return [data, loading, error]
}

export function useLastSubgraphBlock(chainName = "fantom") {
  const [data, loading, error] = useGraph(`{
    _meta {
      block {
        number
      }
    }
  }`, { chainName })
  const [block, setBlock] = useState(null)

  useEffect(() => {
    if (!data) {
      return
    }

    providers[chainName].getBlock(data._meta.block.number).then(block => {
      setBlock(block)
    })
  }, [data, setBlock])

  return [block, loading, error]
}

export function useTradersData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
  const [closedPositionsData, loading, error] = useGraph(`{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`, { chainName })
  const [feesData] = useFeesData({ from, to, chainName })
  const marginFeesByTs = useMemo(() => {
    if (!feesData) {
      return {}
    }

    let feesCumulative = 0
    return feesData.reduce((memo, { timestamp, margin: fees}) => {
      feesCumulative += fees
      memo[timestamp] = {
        fees,
        feesCumulative
      }
      return memo
    }, {})
  }, [feesData])

  let ret = null
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;
  const data = closedPositionsData ? sortBy(closedPositionsData.tradingStats, i => i.timestamp).map(dataItem => {
    const longOpenInterest = dataItem.longOpenInterest / 1e30
    const shortOpenInterest = dataItem.shortOpenInterest / 1e30
    const openInterest = longOpenInterest + shortOpenInterest

    // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
    // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

    const profit = dataItem.profit / 1e30
    const loss = dataItem.loss / 1e30
    const profitCumulative = dataItem.profitCumulative / 1e30
    const lossCumulative = dataItem.lossCumulative / 1e30
    const pnlCumulative = profitCumulative - lossCumulative
    const pnl = profit - loss
    currentProfitCumulative += profit
    currentLossCumulative -= loss
    currentPnlCumulative += pnl
    return {
      longOpenInterest,
      shortOpenInterest,
      openInterest,
      profit,
      loss: -loss,
      profitCumulative,
      lossCumulative: -lossCumulative,
      pnl,
      pnlCumulative,
      timestamp: dataItem.timestamp,
      currentPnlCumulative,
      currentLossCumulative,
      currentProfitCumulative
    }
  }) : null

  if (data && data.length > 0) {
    console.log('data', data)
    const maxProfit = maxBy(data, item => item.profit).profit
    const maxLoss = minBy(data, item => item.loss).loss
    const maxProfitLoss = Math.max(maxProfit, -maxLoss)

    const maxPnl = maxBy(data, item => item.pnl).pnl
    const minPnl = minBy(data, item => item.pnl).pnl
    const maxCurrentCumulativePnl = maxBy(data, item => item.currentPnlCumulative).currentPnlCumulative
    const minCurrentCumulativePnl = minBy(data, item => item.currentPnlCumulative).currentPnlCumulative

    const currentProfitCumulative = data[data.length - 1].currentProfitCumulative
    const currentLossCumulative = data[data.length - 1].currentLossCumulative
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(currentProfitCumulative, -currentLossCumulative),

      maxAbsPnl: Math.max(
        Math.abs(maxPnl),
        Math.abs(minPnl),
      ),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
      
    }

    ret = {
      data,
      stats
    }
  }

  return [ret, loading]
}

function getSwapSourcesFragment(skip = 0, from, to) {
  return `
    hourlyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      source
      swap
    }
  `
}
export function useSwapSources({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to)}
    b: ${getSwapSourcesFragment(1000, from, to)}
    c: ${getSwapSourcesFragment(2000, from, to)}
    d: ${getSwapSourcesFragment(3000, from, to)}
    e: ${getSwapSourcesFragment(4000, from, to)}
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })

  let data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const {a, b, c, d, e} = graphData
    const all = [...a, ...b, ...c, ...d, ...e]

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainName][item.source] || item.source
      if (!acc[source]) {
        acc[source] = 0
      }
      acc[source] += item.swap / 1e30
      return acc
    }, {})
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource).sort((a, b) => b[1] - a[1]).map(item => item[0]).slice(0, 30)
    )

    let ret = chain(all)
      .groupBy(item => parseInt(item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source = knownSwapSources[chainName][item.source] || item.source
            if (!topVolumeSources.has(source)) {
              source = 'Other'
            }
            if (item.swap != 0) {
              const volume = item.swap / 1e30
              memo[source] = memo[source] || 0
              memo[source] += volume
              all += volume
            }
            return memo
          }, {})
        }

        retItem.all = all

        return retItem
      })
      .sortBy(item => item.timestamp)
      .value()

    return ret
  }, [graphData])

  return [data, loading, error]
}

export function useTotalVolumeFromServer() {
  const [data, loading] = useRequest('https://api-v2.morphex.trade/total_volume')

  return useMemo(() => {
    if (!data) {
      return [data, loading]
    }

    // const total = data.reduce((memo, item) => {
    //   return memo + parseInt(item.data.volume) / 1e30
    // }, 0)
    const volumeNew = parseInt(data.volumeNew) / 1e30
    const volumeOld = parseInt(data.volumeOld) / 1e30
    const total = parseInt(data.volume) / 1e30
    return [volumeNew, volumeOld, total, loading]
  }, [data, loading])
}

function getServerHostnameDailyVolume(chainName) {
  if (chainName == "bsc") {
    return 'https://api-v2.morphex.trade/daily_volume?chainId=56'
  }
  return 'https://api-v2.morphex.trade/daily_volume'
}

export function useVolumeDataRequest(url, defaultValue, from, to, fetcher) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultValue)

  useEffect(async () => {
    try {
      setLoading(true)
      const data = await fetcher(url)
      setData(data)
    } catch (ex) {
      console.error(ex)
      setError(ex)
    }
    setLoading(false)
  }, [url, from, to])

  return [data, loading, error]
}

export function useVolumeDataFromServer({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const [data, loading] = useVolumeDataRequest(getServerHostnameDailyVolume(chainName), null, from, to, async url => {
    let after
    const ret = []
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const res = await (await fetch(url + (after ? `?after=${after}` : ''))).json()
      if (res.length === 0) return ret
      for (const item of res) {
        if (item.data.timestamp < from) {
          return ret
        }
        ret.push(item)
      }
      after = res[res.length - 1].id
    }
  })

  const ret = useMemo(() => {
    if (!data) {
      return null
    }

    const tmp = data.reduce((memo, item) => {
      const timestamp = item.data.timestamp
      if (timestamp < from || timestamp > to) {
        return memo
      }

      let type
      if (item.data.action === 'Swap') {
        type = 'swap'
      } else if (item.data.action === 'SellUSDG') {
        type = 'burn'
      } else if (item.data.action === 'BuyUSDG') {
        type = 'mint'
      } else if (item.data.action.includes('LiquidatePosition')) {
        type = 'liquidation'
      } else {
        type = 'margin'
      }
      const volume = Number(item.data.volume) / 1e30
      memo[timestamp] = memo[timestamp] || {}
      memo[timestamp][type] = memo[timestamp][type] || 0
      memo[timestamp][type] += volume
      return memo
    }, {})

    let cumulative = 0
    const cumulativeByTs = {}
    return Object.keys(tmp).sort().map(timestamp => {
      const item = tmp[timestamp]
      let all = 0

      let movingAverageAll
      const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
      }

      PROPS.forEach(prop => {
        if (item[prop]) all += item[prop]
      })
      cumulative += all
      cumulativeByTs[timestamp] = cumulative
      return {
        timestamp,
        all,
        cumulative,
        movingAverageAll,
        ...item
      }
    })
  }, [data, from, to])

  return [ret, loading]
}

export function useUsersData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })

  const prevUniqueCountCumulative = {}
  let cumulativeNewUserCount = 0;
  const data = graphData ? sortBy(graphData.userStats, 'timestamp').map(item => {
    const newCountData = ['', 'Swap', 'Margin', 'MintBurn'].reduce((memo, type) => {
      memo[`new${type}Count`] = prevUniqueCountCumulative[type]
        ? item[`unique${type}CountCumulative`] - prevUniqueCountCumulative[type]
        : item[`unique${type}Count`]
      prevUniqueCountCumulative[type] = item[`unique${type}CountCumulative`]
      return memo
    }, {})
    cumulativeNewUserCount += newCountData.newCount;
    const oldCount = item.uniqueCount - newCountData.newCount
    const oldPercent = (oldCount / item.uniqueCount * 100).toFixed(1)
    return {
      all: item.uniqueCount,
      uniqueSum: item.uniqueSwapCount + item.uniqueMarginCount + item.uniqueMintBurnCount,
      oldCount,
      oldPercent,
      cumulativeNewUserCount,
      ...newCountData,
      ...item
    }
  }) : null

  return [data, loading, error]
}

export function useFundingRateData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })


  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      const symbol = tokenSymbols[item.token]
      if (symbol === 'MIM' || symbol === 'CAKE') {
        return memo
      }
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp
      }
      const group = memo[item.timestamp]
      const timeDelta = parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600

      let fundingRate = 0
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate
        const divisor = timeDelta / 86400
        fundingRate = fundingDelta / divisor / 10000 * 365
      }
      group[symbol] = fundingRate
      return memo
    }, {})
    
    return fillNa(sortBy(Object.values(groups), 'timestamp'))
  }, [graphData])

  return [data, loading, error]
}

const MOVING_AVERAGE_DAYS = 7
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS

export function useVolumeData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
	const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const timestampProp = chainName === "arbitrum" ? "id" : "timestamp"
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: ${timestampProp},
      orderDirection: desc
      where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
      subgraphError: allow
    ) {
      ${timestampProp}
      ${PROPS.join('\n')}
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })

  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    let ret =  sortBy(graphData.volumeStats, timestampProp).map(item => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach(prop => {
        ret[prop] = item[prop] / 1e30
        all += ret[prop]
      })
      ret.all = all
      return ret
    })

    let cumulative = 0
    const cumulativeByTs = {}
    return ret.map(item => {
      cumulative += item.all

      let movingAverageAll
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
      }

      return {
        movingAverageAll,
        cumulative,
        ...item
      }
    })
  }, [graphData])

  return [data, loading, error]
}

export function useFeesData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
      timestamp
    }
  }`
  let [feesData, loading, error] = useGraph(feesQuery, {
    chainName
  })

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null
    }

    let chartData = sortBy(feesData.feeStats, 'id').map(item => {
      const ret = { timestamp: item.timestamp || item.id };

      PROPS.forEach(prop => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30
        }
      })

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0)
      return ret
    })

    let cumulative = 0
    const cumulativeByTs = {}
    return chain(chartData)
      .groupBy(item => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, 'all')
        cumulative += all

        let movingAverageAll
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll
        }
        PROPS.forEach(prop => {
           ret[prop] = sumBy(values, prop)
        })
        cumulativeByTs[timestamp] = cumulative
        return ret
      })
      .value()
      .filter(item => item.timestamp >= from)
  }, [feesData])

  return [feesChartData, loading, error]
}

export function useAumPerformanceData({ from = FIRST_DATE_TS, to = NOW_TS, groupPeriod }) {
  const [feesData, feesLoading] = useFeesData({ from, to, groupPeriod })
  const [glpData, glpLoading] = useGlpData({ from, to, groupPeriod })
  const [volumeData, volumeLoading] = useVolumeData({ from, to, groupPeriod })

  const dailyCoef = 86400 / groupPeriod

  const data = useMemo(() => {
    if (!feesData || !glpData || !volumeData) {
      return null
    }

    const ret = feesData.map((feeItem, i) => {
      const glpItem = glpData[i]
      const volumeItem = volumeData[i]
      let apr = (feeItem?.all && glpItem?.aum) ? feeItem.all /  glpItem.aum * 100 * 365 * dailyCoef : null
      if (apr > 10000) {
        apr = null
      }
      let usage = (volumeItem?.all && glpItem?.aum) ? volumeItem.all / glpItem.aum * 100 * dailyCoef : null
      if (usage > 10000) {
        usage = null
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage
      }
    })
    const averageApr = ret.reduce((memo, item) => item.apr + memo, 0) / ret.length
    ret.forEach(item => item.averageApr = averageApr)
    const averageUsage = ret.reduce((memo, item) => item.usage + memo, 0) / ret.length
    ret.forEach(item => item.averageUsage = averageUsage)
    return ret
  }, [feesData, glpData, volumeData])

  return [data, feesLoading || glpLoading || volumeLoading]
}

export function useGlpData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
  const timestampProp = chainName === 'arbitrum' ? 'id' : 'timestamp'
  const query = `{
    glpStats(
      first: 1000
      orderBy: ${timestampProp}
      orderDirection: desc
      where: {
        period: daily
        ${timestampProp}_gte: ${from}
        ${timestampProp}_lte: ${to}
      }
      subgraphError: allow
    ) {
      ${timestampProp}
      aumInUsdg
      glpSupply
      distributedUsd
      distributedEth
    }
  }`
  let [data, loading, error] = useGraph(query, { chainName })

  let cumulativeDistributedUsdPerGlp = 0
  let cumulativeDistributedEthPerGlp = 0
  const glpChartData = useMemo(() => {
    if (!data) {
      return null
    }

    let prevGlpSupply
    let prevAum

    let ret = sortBy(data.glpStats, item => item[timestampProp]).filter(item => item[timestampProp] % 86400 === 0).reduce((memo, item) => {
      const last = memo[memo.length - 1]

      const aum = Number(item.aumInUsdg) / 1e18
      const glpSupply = Number(item.glpSupply) / 1e18

      const distributedUsd = Number(item.distributedUsd) / 1e30
      const distributedUsdPerGlp = (distributedUsd / glpSupply) || 0
      cumulativeDistributedUsdPerGlp += distributedUsdPerGlp

      const distributedEth = Number(item.distributedEth) / 1e18
      const distributedEthPerGlp = (distributedEth / glpSupply) || 0
      cumulativeDistributedEthPerGlp += distributedEthPerGlp

      const glpPrice = aum / glpSupply
      const timestamp = parseInt(item[timestampProp])

      const newItem = {
        timestamp,
        aum,
        glpSupply,
        glpPrice,
        cumulativeDistributedEthPerGlp,
        cumulativeDistributedUsdPerGlp,
        distributedUsdPerGlp,
        distributedEthPerGlp
      }

      if (last && last.timestamp === timestamp) {
        memo[memo.length - 1] = newItem
      } else {
        memo.push(newItem)
      }

      return memo
    }, []).map(item => {
      let { glpSupply, aum } = item
      if (!glpSupply) {
        glpSupply = prevGlpSupply
      }
      if (!aum) {
        aum = prevAum
      }
      item.glpSupplyChange = prevGlpSupply ? (glpSupply - prevGlpSupply) / prevGlpSupply * 100 : 0
      if (item.glpSupplyChange > 1000) {
        item.glpSupplyChange = 0
      }
      item.aumChange = prevAum ? (aum - prevAum) / prevAum * 100 : 0
      if (item.aumChange > 1000) {
        item.aumChange = 0
      }
      prevGlpSupply = glpSupply
      prevAum = aum
      return item
    })

    ret = fillNa(ret)
    return ret
  }, [data])
  
  return [glpChartData, loading, error]
}

export function useGlpPerformanceData(glpData, feesData, { from = FIRST_DATE_TS, chainName = "fantom" } = {}) {
  const [btcPrices] = useCoingeckoPrices('BTC', { from })
  const [ethPrices] = useCoingeckoPrices('ETH', { from })
  const [ftmPrices] = useCoingeckoPrices('FTM', { from })
  const [bnbPrices] = useCoingeckoPrices('BNB', { from })

  const glpPerformanceChartData = useMemo(() => {
    if (!btcPrices || !ethPrices || !ftmPrices || !glpData || !feesData || !feesData.length) {
      return null
    }

    const glpDataById = glpData.reduce((memo, item) => {
      memo[item.timestamp] = item
      return memo
    }, {})

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item
      return memo
    })

    let BTC_WEIGHT = 0
    let ETH_WEIGHT = 0
    let FTM_WEIGHT = 0
    let BNB_WEIGHT = 0

    if (chainName === "bsc") {
      BTC_WEIGHT = 0.2
      ETH_WEIGHT = 0.15
      BNB_WEIGHT = 0.15
    } else {
      BTC_WEIGHT = 0.14
      ETH_WEIGHT = 0.14
      FTM_WEIGHT = 0.22
    }

    const STABLE_WEIGHT = 1 - BTC_WEIGHT - ETH_WEIGHT - FTM_WEIGHT - BNB_WEIGHT
    const GLP_START_PRICE = glpDataById[btcPrices[0].timestamp]?.glpPrice || 1

    const btcFirstPrice = btcPrices[0]?.value
    const ethFirstPrice = ethPrices[0]?.value
    const ftmFirstPrice = ftmPrices[0]?.value
    const bnbFirstPrice = bnbPrices[0]?.value

    let indexBtcCount = GLP_START_PRICE * BTC_WEIGHT / btcFirstPrice
    let indexEthCount = GLP_START_PRICE * ETH_WEIGHT / ethFirstPrice
    let indexFtmCount = GLP_START_PRICE * FTM_WEIGHT / ftmFirstPrice
    let indexBnbCount = GLP_START_PRICE * BNB_WEIGHT / bnbFirstPrice
    let indexStableCount = GLP_START_PRICE * STABLE_WEIGHT

    const lpBtcCount = GLP_START_PRICE * 0.5 / btcFirstPrice
    const lpEthCount = GLP_START_PRICE * 0.5 / ethFirstPrice
    const lpFtmCount = GLP_START_PRICE * 0.5 / ftmFirstPrice
    const lpBnbCount = GLP_START_PRICE * 0.5 / bnbFirstPrice

    const ret = []
    let cumulativeFeesPerGlp = 0
    let lastGlpItem
    let lastFeesItem

    let prevEthPrice = 1750
    let prevFtmPrice = 0.4
    let prevBnbPrice = 247
    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value
      const ethPrice = ethPrices[i]?.value || prevEthPrice
      const ftmPrice = ftmPrices[i]?.value || prevFtmPrice
      const bnbPrice = bnbPrices[i]?.value || prevBnbPrice
      prevEthPrice = ethPrice
      prevFtmPrice = ftmPrice
      prevBnbPrice = bnbPrice

      const timestampGroup = parseInt(btcPrices[i].timestamp / 86400) * 86400
      const glpItem = glpDataById[timestampGroup] || lastGlpItem
      lastGlpItem = glpItem

      const glpPrice = glpItem?.glpPrice
      const glpSupply = glpItem?.glpSupply
      
      const feesItem = feesDataById[timestampGroup] || lastFeesItem
      lastFeesItem = feesItem

      const dailyFees = feesItem?.all

      const syntheticPrice = (
        indexBtcCount * btcPrice
        + indexEthCount * ethPrice
        + indexFtmCount * ftmPrice
        + indexBnbCount * bnbPrice
        + indexStableCount
      )

      // rebalance each day. can rebalance each X days
      if (i % 1 == 0) {
        indexBtcCount = syntheticPrice * BTC_WEIGHT / btcPrice
        indexEthCount = syntheticPrice * ETH_WEIGHT / ethPrice
        indexFtmCount = syntheticPrice * FTM_WEIGHT / ftmPrice
        indexBnbCount = syntheticPrice * BNB_WEIGHT / bnbPrice
        indexStableCount = syntheticPrice * STABLE_WEIGHT
      }

      const lpBtcPrice = (lpBtcCount * btcPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(btcPrice / btcFirstPrice))
      const lpEthPrice = (lpEthCount * ethPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(ethPrice / ethFirstPrice))
      const lpFtmPrice = (lpFtmCount * ftmPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(ftmPrice / ftmFirstPrice))
      const lpBnbPrice = (lpBnbCount * bnbPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(bnbPrice / bnbFirstPrice))

      if (dailyFees && glpSupply) {
        // const INCREASED_GLP_REWARDS_TIMESTAMP = 1635714000
        const GLP_REWARDS_SHARE = 0.6
        const collectedFeesPerGlp = dailyFees / glpSupply * GLP_REWARDS_SHARE
        cumulativeFeesPerGlp += collectedFeesPerGlp
      }

      let glpPlusFees = glpPrice
      if (glpPrice && glpSupply && cumulativeFeesPerGlp) {
        glpPlusFees = glpPrice + cumulativeFeesPerGlp
      }

      let glpApr
      let glpPlusDistributedUsd
      let glpPlusDistributedEth
      if (glpItem) {
        if (glpItem.cumulativeDistributedUsdPerGlp) {
          glpPlusDistributedUsd = glpPrice + glpItem.cumulativeDistributedUsdPerGlp
          // glpApr = glpItem.distributedUsdPerGlp / glpPrice * 365 * 100 // incorrect?
        }
        if (glpItem.cumulativeDistributedEthPerGlp) {
          glpPlusDistributedEth = glpPrice + glpItem.cumulativeDistributedEthPerGlp * ethPrice
        }
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        lpFtmPrice,
        lpBnbPrice,
        glpPrice,
        btcPrice,
        ethPrice,
        glpPlusFees,
        glpPlusDistributedUsd,
        glpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        indexFtmCount,
        indexBnbCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        FTM_WEIGHT,
        BNB_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: (glpPrice / lpEthPrice * 100).toFixed(2),
        performanceLpEthCollectedFees: (glpPlusFees / lpEthPrice * 100).toFixed(2),
        performanceLpEthDistributedUsd: (glpPlusDistributedUsd / lpEthPrice * 100).toFixed(2),
        performanceLpEthDistributedEth: (glpPlusDistributedEth / lpEthPrice * 100).toFixed(2),

        performanceLpBtcCollectedFees: (glpPlusFees / lpBtcPrice * 100).toFixed(2),

        performanceLpFtmCollectedFees: (glpPlusFees / lpFtmPrice * 100).toFixed(2),

        performanceLpBnbCollectedFees: (glpPlusFees / lpBnbPrice * 100).toFixed(2),

        performanceSynthetic: (glpPrice / syntheticPrice * 100).toFixed(2),
        performanceSyntheticCollectedFees: (glpPlusFees / syntheticPrice * 100).toFixed(2),
        performanceSyntheticDistributedUsd: (glpPlusDistributedUsd / syntheticPrice * 100).toFixed(2),
        performanceSyntheticDistributedEth: (glpPlusDistributedEth / syntheticPrice * 100).toFixed(2),

        glpApr
      })
    }

    return ret
  }, [btcPrices, ethPrices, glpData, feesData])

  return [glpPerformanceChartData]
}

export function useTokenStats({ 
  from = FIRST_DATE_TS,
  to = NOW_TS,
  period = 'daily',
  chainName = "fantom" 
} = {}) {

  const getTokenStatsFragment = ({skip = 0} = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({skip: 1000})},
    c: ${getTokenStatsFragment({skip: 2000})},
    d: ${getTokenStatsFragment({skip: 3000})},
    e: ${getTokenStatsFragment({skip: 4000})},
    f: ${getTokenStatsFragment({skip: 5000})},
  }`

  const [graphData, loading, error] = useGraph(query, { chainName })

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null;
    }

    const fullData = Object.values(graphData).reduce((memo, records) => {
      memo.push(...records);
      return memo;
    }, []);

    const retrievedTokens = new Set();

    const timestampGroups = fullData.reduce((memo, item) => {
      const {timestamp, token, ...stats} = item;

      const symbol = tokenSymbols[token] || token;

      if (symbol !== "CAKE") {
        retrievedTokens.add(symbol);

        memo[timestamp] = memo[timestamp || 0] || {};

        memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(stats.poolAmountUsd) / 1e30,
        };
      }

      return memo;
    }, {});

    const poolAmountUsdRecords = [];

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
        const poolAmountUsdRecord = Object.entries(dataItem).reduce((memo, [token, stats]) => {
            memo.all += stats.poolAmountUsd;
            memo[token] = stats.poolAmountUsd;
            memo.timestamp = timestamp;

            return memo;
        }, {all: 0});

        poolAmountUsdRecords.push(poolAmountUsdRecord);
    })

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    };
  }, [graphData, loading])

  return [data, loading, error]
}

export function useReferralsData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "fantom" } = {}) {
  const query = `{
    globalStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      volume
      volumeCumulative
      totalRebateUsd
      totalRebateUsdCumulative
      discountUsd
      discountUsdCumulative
      referrersCount
      referrersCountCumulative
      referralCodesCount
      referralCodesCountCumulative
      referralsCount
      referralsCountCumulative
      timestamp
    }
  }`

  const subgraph = chainName === "fantom"
    ? "morphex-fantom-referrals-new/version/latest"
    : "morphex-bsc-referrals/version/latest"
  const [graphData, loading, error] = useGraph(query, { subgraph })

  const data = graphData ? sortBy(graphData.globalStats, 'timestamp').map(item => {
    const totalRebateUsd = item.totalRebateUsd / 1e30
    const discountUsd = item.discountUsd / 1e30
    return {
      ...item,
      volume: item.volume / 1e30,
      volumeCumulative: item.volumeCumulative / 1e30,
      totalRebateUsd,
      totalRebateUsdCumulative: item.totalRebateUsdCumulative / 1e30,
      discountUsd,
      referrerRebateUsd: totalRebateUsd - discountUsd,
      discountUsdCumulative: item.discountUsdCumulative / 1e30,
      referralCodesCount: parseInt(item.referralCodesCount),
      referralCodesCountCumulative: parseInt(item.referralCodesCountCumulative),
      referrersCount: parseInt(item.referrersCount),
      referrersCountCumulative: parseInt(item.referrersCountCumulative),
      referralsCount: parseInt(item.referralsCount),
      referralsCountCumulative: parseInt(item.referralsCountCumulative),
    }
  }) : null

  return [data, loading, error]
}
